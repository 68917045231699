@import '~plugin_frontend_core/variables';
@import '~plugin_frontend_core/mixins';

$banner-carousel-absolute:          true !default;

.content-carousel {
    padding: 0 40px;

    @include media-breakpoint-up(md) {
        padding: 0 50px;
    }

    @if $banner-carousel-absolute {
        .slick-dots {
            position: absolute;
            bottom: $gutter / 2;
        }

        .btn {
            white-space: nowrap;
        }
    }

    .slick-slide {
        padding: 0 2px;

        @include media-breakpoint-up(md) {
            padding: 0 15px;
        }
    }

    .slick-prev {
        left: 3px;

        @include media-breakpoint-up(md) {
            left: 0;
        }
    }

    .slick-next {
        right: 3px;

        @include media-breakpoint-up(md) {
            right: 0;
        }
    }
}

.content-carousel-preview {
    .slick-track > :not(.slick-center) {
        background-color: black;
        transform: scale(0.94);

        > div {
            opacity: .6;
        }
    }

    .slick-slide {
        margin-left: -2em;
        margin-right: -2em;
        transition: all 300ms ease;
    }

    .slick-center > div {
        z-index: 3;
        position: relative;
    }
}
